class Hero {
  submitBtns = document.querySelectorAll('.submitBtn') 
  modal = document.querySelector('.md')
  submitSnowboard = document.querySelector('.submitSnowboard')
  serviceType = document.querySelector('.serviceType')
  submitTransferBtn = document.querySelector('.submitTransfer')
  validate = [document.querySelector('.date'), document.querySelector('.people')]
  validateTransfer = [document.querySelector('.transferDate'), document.querySelector('.transferPeople')]
  hiddenInputs = document.querySelectorAll('.md input[type=number], .md input[type=date]')
  hiddenTourTitle = document.querySelector('.hiddenTourTitle')

  constructor() {
    this.submitTransfer()

    this.submitSnowboard.addEventListener('click', () => {
      this.serviceType.setAttribute('value', 'Сноуборд Урок')
      this.hiddenTourTitle.setAttribute('value', 'Узана')

      this.validate.forEach((subArr: any, i) => {
        this.hiddenInputs[i].setAttribute('value', subArr.value)
      })
    })

    this.submitTransferBtn.addEventListener('click', (e:any) => {
      this.serviceType.setAttribute('value', 'Трансфер')

      this.validateTransfer.forEach((subArr: any, i) => {
        console.log(e.target.value)
        this.hiddenInputs[i].setAttribute('value', subArr.value)
      })
    })
  }

  submitTransfer() {
    const selectCity = document.querySelector('.selectCity') as any
    const hiddenTourTitle = document.querySelector('.hiddenTourTitle')
    
      let observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation:any) {
          if (mutation.type === "attributes") {
            hiddenTourTitle.setAttribute('value', mutation.target.getAttribute('data-type'))
          }        
        });
      });
      
      observer.observe(selectCity, {
        attributes: true
      });      
  }

}

new Hero;